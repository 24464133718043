import { useToast, POSITION } from "vue-toastification";

const toast = useToast();
export default {
  namespaced: true,
  state: {
    notification: {
      show: true,
      message: "",
      type: "",
      position: ""
    }
  },
  getters: {
    getNotification: state => state.notification,
    showNotification: state => state.notification.show
  },
  mutations: {
    setNotification(state, payload) {
      state.notification = payload;
    }
  },
  actions: {
    setNotification({ commit }, payload) {
      toast(payload.message, { position: POSITION.BOTTOM_RIGHT, timeout: 3000, type: payload.type });
    }
  }
};