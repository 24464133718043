import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export default {
  namespaced: true,
  state: {
    businessList: [],
    peopleList: []
  },
  getters: {
    getBusinessList: state => state.businessList.data,
    getPeopleList: state => state.peopleList.data
  },
  mutations: {
    setBusinessList(state, payload) {
      state.businessList = payload;
    },
    setPeopleList(state, payload) {
      state.peopleList = payload;
    }
  },
  actions: {
    getBusinessList({ commit }) {
      return new Promise((resolve, reject) => {
        ApiService.get(`business-list`)
          .then(({ data }) => {
            commit("setBusinessList", data);
            resolve(data);
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    },
    getPeopleList({ commit }) {
      return new Promise((resolve, reject) => {
        ApiService.get(`people-list`)
          .then(({ data }) => {
            commit("setPeopleList", data);
            resolve(data);
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    },
    createBusinessList({ commit }, data) {
      return new Promise((resolve, reject) => {
        ApiService.post(`business-list`, data)
          .then(({ data }) => {
            commit("setBusinessList", data);
            resolve(data);
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    },
    createPeopleList({ commit }, data) {
      return new Promise((resolve, reject) => {
        ApiService.post(`people-list`, data)
          .then(({ data }) => {
            commit("setPeopleList", data);
            resolve(data);
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    },
    addToList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post("add-to-list", payload)
          .then(({ data }) => {
            resolve(data);
          }).catch(({ response }) => {
          reject();
        });
      });
    }
  }
};