import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export default {
  namespaced: true,
  state: {
    emailVerificationRequest: {},
    emailVerificationRequestsList: {},
  },
  getters: {
    getEmailVerificationRequestList: (state) =>
      state.emailVerificationRequestsList,
  },
  mutations: {
    setEmailVerificationRequest(state, payload) {
      state.emailVerificationRequest = payload;
    },
    setEmailVerificationRequestList(state, payload) {
      state.emailVerificationRequestsList = payload;
    },
  },
  actions: {
    save({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return ApiService.post(`email-verification-v2`, payload)
          .then(({ data }) => {
            resolve(data);
            return data;
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    },
    getEmailVerificationRequestList({ commit }) {
      return new Promise((resolve, reject) => {
        ApiService.get(`my-email-verification-lists-v2`)
          .then((response) => {
            commit("setEmailVerificationRequestList", response.data);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
